






















import {
  computed,
  defineComponent,
  reactive,
  nextTick,
} from "@vue/composition-api";
import { logicNot, resolveUnref, useVModel, whenever } from "@vueuse/core";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  emits: ["eventDeleted", "update:isLoading", "update:isOpen"],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    event: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit, root }) {
    const axios = computed(
      () => root.$store.getters["api/getInstance"] as AxiosInstance
    );

    const isDialogLoading = useVModel(props, "isLoading", emit, {
      eventName: "update:isLoading",
      defaultValue: false,
      passive: true,
    });

    const isDialogOpen = useVModel(props, "isOpen", emit, {
      eventName: "update:isOpen",
      defaultValue: false,
      passive: true,
    });

    const model = reactive({
      prefix: "",
    });

    const resetModel = () => {
      model.prefix = "";
    };

    whenever(logicNot(isDialogOpen), resetModel);

    const eventId = computed(() => props.event.id as string);

    const deleteEvent = () => {
      const id = resolveUnref(eventId);

      const params = {
        prefix: model.prefix,
      };

      isDialogLoading.value = true;

      axios.value
        .delete(`event/${id}`, { params })
        .then(() => {
          isDialogOpen.value = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("panel.event.successDeleteEvent"),
          });

          emit("eventDeleted");
        })
        .catch(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: root.$tc("panel.event.errorDeleteEvent"),
          });
        })
        .finally(() => {
          isDialogLoading.value = false;
          nextTick(resetModel);
        });
    };

    return {
      isDialogLoading,
      isDialogOpen,
      model,
      deleteEvent,
    };
  },
});
